import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import { toast } from 'react-toastify';
import { nonAuthPostAxios } from "../../Utilities/commonAxios";
import { useSelector } from 'react-redux';

const ChangePassword = () => {
    let isAuthenticated = useSelector(state => state.auth)
    const navigate = useNavigate()
    const [isFormValidated, setIsFormValidated] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const handleChangePassword = async () => {
        setIsFormValidated(true)
        if (oldPassword && newPassword && confirmNewPassword) {
            if (newPassword === confirmNewPassword) {
                if (isAuthenticated?.postItems?.Customer?.plain_password === oldPassword) {
                    let { data: passwordAPIResponse } = await nonAuthPostAxios({
                        url: `${process.env.REACT_APP_API_URL}/customer_reset_password`,
                        data: {
                            "id": isAuthenticated?.postItems?.Customer?.id,
                            "password": newPassword,
                            "plain_password": newPassword
                        }
                    })
                    if (passwordAPIResponse?.Api_success === "True") {
                        toast.success('Password changed successfully!')
                        localStorage.clear()
                        navigate('/login')
                    } else {
                        toast.error(passwordAPIResponse?.Api_message)
                    }
                } else {
                    toast.error('Invalid old password!')
                }
            } else {
                toast.error('New/Confirm password must match!')
            }
        }
    }
    return (
        <>
            <section className="common-top-style mybooking-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-md-2 col-12"></div>
                        <div className="col-xl-5 col-md-8 col-12">
                            <h1 className="about-heading mb-2">Change Password</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-md-2 col-12"></div>
                        <div className="col-xl-5 col-md-8 col-12">
                            <div className="setings-box-style">
                                <form>
                                    <div className="mb-2">
                                        <label htmlFor="oldPassword" className="form-label custom-label">Old Password</label>
                                        <input type="password" className="form-control" id="oldPassword" aria-describedby="oldPassword" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                                    </div>
                                    {isFormValidated && !oldPassword && (
                                        <p className="distance-style">Please enter the old password</p>
                                    )}
                                    <div className="mb-2">
                                        <label htmlFor="newPassword" className="form-label custom-label">New Password</label>
                                        <input type="password" className="form-control" id="newPassword" aria-describedby="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                    </div>
                                    {isFormValidated && !newPassword && (
                                        <p className="distance-style">Please enter the new password</p>
                                    )}
                                    <div className="mb-2">
                                        <label htmlFor="confirmNewPassword" className="form-label custom-label">Confirm Password</label>
                                        <input type="password" className="form-control" id="confirmNewPassword" aria-describedby="confirmNewPassword" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                                    </div>
                                    {isFormValidated && !confirmNewPassword && (
                                        <p className="distance-style">Please enter the confirm password</p>
                                    )}
                                    {isFormValidated && newPassword !== confirmNewPassword && (
                                        <p className="distance-style">New/Confirm password must match!</p>
                                    )}
                                    <div className="mt-3">
                                        <Link to="#" className="btn btn-primary check-btn" onClick={handleChangePassword}>Submit</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ChangePassword