import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { authLogin } from "../../Reduxstore/authSlice";
import { useDispatch, useSelector } from 'react-redux';
import { manageLoader } from "../../Reduxstore/loaderSlice";

const Login = () => {
    let isBooked = useSelector(state => state.booking)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const loginForm = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            emailPhone: '',
            password: '',
        },
        validationSchema: Yup.object({
            emailPhone: Yup.string().required("Please Enter Email/Phone"),
            password: Yup.string().required("Please Enter password")
        }),
        onSubmit: async (values) => {
            if (!loginForm.dirty) {
                toast.error('No data has changed!')
            } else {
                dispatch(manageLoader(true));
                let loginResponseData = await dispatch(authLogin({ user_id: values.emailPhone, password: values.password }));
                dispatch(manageLoader(false));
                if (loginResponseData?.payload?.api_success) {
                    if (Object.keys(isBooked?.inCompleteBookingDetails).length > 0 && isBooked?.isBookingInitiated) {
                        navigate('/confirm-booking')
                    } else {
                        toast.success("Login successfully!")
                        navigate('/my-bookings')
                    }
                } else {
                    toast.error(loginResponseData?.payload)
                }
            }
        }
    })
    return (
        <>
            <h1 className="local-heading">Welcome To The Movers Online</h1>
            <form onSubmit={loginForm.handleSubmit}>
                <div className="mb-2">
                    <label htmlFor="emailPhone" className="form-label text-start custom-label">Email or Phone</label>
                    <input type="text" className="form-control custom-input" id="emailPhone" name="emailPhone" aria-describedby="emailPhone" values={loginForm.values.emailPhone} onChange={loginForm.handleChange} />
                </div>
                {loginForm.errors.emailPhone && (
                    <div className="text-danger">{loginForm.errors.emailPhone}</div>
                )}
                <div className="mb-3">
                    <label htmlFor="password" className="form-label text-start custom-label">Password</label>
                    <input type="password" className="form-control custom-input" id="password" name="password" aria-describedby="emaipasswordlHelp" values={loginForm.values.password} onChange={loginForm.handleChange} />
                </div>
                {loginForm.errors.password && (
                    <div className="text-danger">{loginForm.errors.password}</div>
                )}
                <div className='register-btn'>
                    <button type="submit" className='btn btn-success register-btn-style'>Login</button>
                </div>
                <div className='login-style'>
                    Don't have an account? - <Link to={"/register"} className='login-text'>Register</Link>
                </div>
            </form>
        </>
    )
}
export default Login;