import React from "react";
const AdditionalServiceItem = ({ quantity, iconSrc, description, isx }) => (
    <div className="additional-service-item">
        <div className="top-left-corner">
            <p className="top-left-text">{isx ? `x${quantity}` : quantity}</p>
        </div>
        <div className="icon-container">
            <img src={iconSrc} className="additional_icons" alt={description} />
        </div>
        <p className="additional-service-text">{description}</p>
    </div>
);

export default AdditionalServiceItem;