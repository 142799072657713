import React from 'react';
import Index from '../Component';
import About from '../Component/about';
import Login from '../Component/Authentication/login';
import Register from '../Component/Authentication/register';
import TipsPending from '../Component/Blog/tips_pending';
import ConfirmBooking from '../Component/Booking/confirmbooking';
import InitiateBooking from '../Component/Booking/initiatebooking';
import ContactUs from '../Component/contactus';
import ChangePassword from '../Component/Customer/changePassword';
import MybookingDeatils from '../Component/Customer/myBookingDetails';
import MyBookingLists from '../Component/Customer/myBookingLists';
import Settings from '../Component/Customer/settings';
import Faq from '../Component/faq';
import HelpSupport from '../Component/helpSupport';
import Resetpassword from '../Component/resetpassword';
import Services from '../Component/services';
import BudgetMoving from '../Component/ServicesWeOffer/budgetmoving';
import DisposalMoving from '../Component/ServicesWeOffer/disposal';
import ManpowerMoving from '../Component/ServicesWeOffer/manpower';
import PremimumMoving from '../Component/ServicesWeOffer/premium';
import Privacy from '../Terms/privacy';
import Terms from '../Terms/terms';
import HouseMoving from '../Component/Blog/house_moving';
import TipsPindha from '../Component/Blog/tips_pindha';
import PindhaRumha from '../Component/Blog/pindha_rumha';
import DosDont from '../Component/Blog/dos_dont';

const publicRoutes = [
  { path: '/', element: <Index /> },
  { path: '/help-support', element: <HelpSupport /> },
  { path: '/services', element: <Services /> },
  { path: '/about', element: <About /> },
  { path: '/contact-us', element: <ContactUs /> },
  { path: '/terms', element: <Terms /> },
  { path: '/privacy', element: <Privacy /> },
  { path: '/resetpassword', element: <Resetpassword /> },
  { path: '/faq', element: <Faq /> },
  { path: '/budget', element: <BudgetMoving /> },
  { path: '/premimum', element: <PremimumMoving /> },
  { path: '/disposal', element: <DisposalMoving /> },
  { path: '/manpower', element: <ManpowerMoving /> },
  { path: '/tipsPending', element: <TipsPending /> },
  { path: '/houseMoving', element: <HouseMoving /> },
  { path: '/tipsPindha', element: <TipsPindha /> },
  { path: '/pindhaRumha', element: <PindhaRumha /> },
  { path: '/dosdont', element: <DosDont /> },
]

const bookingRoutes = [
  { path: '/initiate-booking', element: <InitiateBooking /> },
  { path: '/confirm-booking', element: <ConfirmBooking /> }
]

const loginRoutes = [
  { path: '/login', element: <Login /> },
  { path: '/register', element: <Register /> }
]

const authRoutes = [
  { path: '/my-bookings', element: <MyBookingLists /> },
  { path: '/mybookingdetails/:enBookingId', element: <MybookingDeatils /> },
  { path: '/settings', element: <Settings /> },
  { path: '/change-password', element: <ChangePassword /> },
]

export { authRoutes, bookingRoutes, loginRoutes, publicRoutes };

