import { useParams } from 'react-router'
import Bud1 from '../../img/bude1.png'
import { BookingDetailsMaster } from "../../Utilities/bookingDetailsMaster";
import { useEffect, useState } from 'react';
import { getAxios } from "../../Utilities/commonAxios";
import AdditionalServiceItem from './additional_item';
import AmountItem from './amount_item';
import BedIcon from "../../img/additional-bed.png";
import BubbleIcon from "../../img/additional-bubble.png";
import DiningTableIcon from "../../img/additional-dining.png";
import InsuranceIcon from "../../img/additional-insuarance.png";
import LongPushIcon from "../../img/additional-longpush.png";
import OfficeTableIcon from "../../img/additional-office.png";
import ShrinkIcon from "../../img/additional-shrink.png";
import StairCarryIcon from "../../img/additional-staircarry.png";
import WardrobeIcon from "../../img/additional-wardrobe.png";
import BoxIcon from "../../img/additional_box.png";
import TailGateIcon from "../../img/additional_tailgate.png";
import ManpowerIcon from "../../img/manpower-additional.png";

const MybookingDeatils = () => {
    const { enBookingId } = useParams()
    const bookingId = atob(enBookingId)
    const [bookingDetailResponse, setBookingDetailResponse] = useState({})
    const [bookingDetail, setBookingDetail] = useState({})
    const getBookingDetailFromAPI = async () => {
        await getAxios({ url: `${process.env.REACT_APP_API_URL}/booking_detail?id=${bookingId}` }).then(response => {
            setBookingDetailResponse(response?.data);
            setBookingDetail(response?.data?.bookings);
        })
    }
    useEffect(() => {
        getBookingDetailFromAPI()
    }, [])
    return (
        <>
            <section className="common-top-style mybooking-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h4 className="details-bud-head">
                                {bookingDetail?.service_type
                                    ? `${bookingDetail?.service_type} Booking`
                                    : ""}
                            </h4>
                            <p className="details-bud-time">
                                {bookingDetail?.booking_date_time}
                            </p>
                        </div>
                    </div>

                    <div className="row mt-3">
                        {/* Column for Address Details and Additional Services */}
                        <div className="col-md-8 col-12 d-flex flex-column align-items-stretch ">
                            {/* Address Details */}
                            <div className="details-card mb-3 pb-3">
                                <div className="details-card-header">
                                    <h5>Address Details</h5>
                                </div>
                                <div className="container pt-3">
                                    <div className="row address-detail-card">
                                        <div className="col-md-4 col-12">
                                            {bookingDetail?.pickup_address &&
                                                bookingDetail?.pickup_address != "" && (
                                                    <>
                                                        <p className="pickup-add-details mb-1">
                                                            <i
                                                                className="fa fa-map-marker pe-1"
                                                                aria-hidden="true"
                                                            ></i>
                                                            Pickup Address
                                                        </p>
                                                        <p className="card-address-style">
                                                            {bookingDetail?.pickup_address}
                                                        </p>
                                                    </>
                                                )}
                                        </div>

                                        <div className="col-md-4 col-12 ">
                                            {bookingDetail?.distance &&
                                                bookingDetail?.distance != 0 && (
                                                    <div className="row">
                                                        <div className="col-4"></div>
                                                        <div className="col-4 align-self-center distance-card mt-3 mb-3">
                                                            <p className="mb-0 ">
                                                                <span className="distance">Distance:</span>
                                                                <br></br>
                                                                <span className="km">
                                                                    {bookingDetail?.distance} Km
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="col-4"></div>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="col-md-4 col-12">
                                            {bookingDetail?.drop_off_address &&
                                                bookingDetail?.drop_off_address != "" && (
                                                    <>
                                                        <p className="pickup-add-details mb-1">
                                                            <i
                                                                className="fa fa-map-marker pe-1"
                                                                aria-hidden="true"
                                                            ></i>
                                                            Drop Address
                                                        </p>
                                                        <p className="card-address-style">
                                                            {bookingDetail?.drop_off_address}
                                                        </p>
                                                    </>
                                                )}
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="details-card-body">
                  <div className="row">
                    <div className="col-12">
                      {bookingDetail?.pickup_address && (
                        <>
                          <p className="pickup-add-details mb-1">
                            <i
                              className="fa fa-map-marker me-1"
                              aria-hidden="true"
                            ></i>
                            Pickup Address
                          </p>
                          <p className="card-address-style">
                            {bookingDetail?.pickup_address}
                          </p>
                        </>
                      )}

                      {bookingDetail?.drop_off_address && (
                        <>
                          <p className="pickup-add-details mb-1">
                            <i
                              className="fa fa-map-marker me-1"
                              aria-hidden="true"
                            ></i>
                            Drop Address
                          </p>
                          <p className="card-address-style">
                            {bookingDetail?.drop_off_address}
                          </p>
                        </>
                      )}
                    </div>
                    {bookingDetail?.distance && (
                      <div className="col-12 align-self-center">
                        <p className="mb-0">
                          <b>Distance:</b> {bookingDetail?.distance} Km
                        </p>
                      </div>
                    )}
                  </div>
                </div> */}
                            </div>

                            {/* Additional Services */}
                            {bookingDetail?.manpower_count != 0 &&
                                bookingDetail.manpower_count != null && (
                                    <div className="details-card">
                                        <div className="details-card-header">
                                            <h5>Additional Services</h5>
                                        </div>
                                        <div className="container">
                                            <div className="details-card-body">
                                                <div className="additional-services-grid">
                                                    {bookingDetail.manpower_count !== 0 &&
                                                        bookingDetail.manpower_count != null && (
                                                            <AdditionalServiceItem
                                                                quantity={bookingDetail.manpower_count}
                                                                iconSrc={ManpowerIcon}
                                                                description="Manpower"
                                                                isx={true}
                                                            />
                                                        )}
                                                    {bookingDetail.bubble !== 0 &&
                                                        bookingDetail.bubble != null && (
                                                            <AdditionalServiceItem
                                                                quantity={bookingDetail.bubble}
                                                                iconSrc={BubbleIcon}
                                                                description="Bubble"
                                                                isx={true}
                                                            />
                                                        )}
                                                    {bookingDetail.dining_table_count !== 0 &&
                                                        bookingDetail.dining_table_count != null && (
                                                            <AdditionalServiceItem
                                                                quantity={bookingDetail.dining_table_count}
                                                                iconSrc={DiningTableIcon}
                                                                description="Dining Table"
                                                                isx={true}
                                                            />
                                                        )}
                                                    {bookingDetail.box_count !== 0 &&
                                                        bookingDetail.box_count != null && (
                                                            <AdditionalServiceItem
                                                                quantity={bookingDetail.box_count}
                                                                iconSrc={BoxIcon}
                                                                description="Boxes"
                                                                isx={true}
                                                            />
                                                        )}
                                                    {bookingDetail.wrapping !== 0 &&
                                                        bookingDetail.wrapping != null && (
                                                            <AdditionalServiceItem
                                                                quantity={bookingDetail.wrapping}
                                                                iconSrc={ShrinkIcon}
                                                                description="Shrink Wrapping"
                                                                isx={true}
                                                            />
                                                        )}
                                                    {bookingDetail.bed_count !== 0 &&
                                                        bookingDetail.bed_count != null && (
                                                            <AdditionalServiceItem
                                                                quantity={bookingDetail.bed_count}
                                                                iconSrc={BedIcon}
                                                                description="Bed"
                                                                isx={true}
                                                            />
                                                        )}
                                                    {bookingDetail.table_count !== 0 &&
                                                        bookingDetail.table_count != null && (
                                                            <AdditionalServiceItem
                                                                quantity={bookingDetail.table_count}
                                                                iconSrc={OfficeTableIcon}
                                                                description="Office Table"
                                                                isx={true}
                                                            />
                                                        )}
                                                    {bookingDetail.wardrobe_count !== 0 &&
                                                        bookingDetail.wardrobe != null && (
                                                            <AdditionalServiceItem
                                                                quantity={bookingDetail.wardrobe_count}
                                                                iconSrc={WardrobeIcon}
                                                                description="Wardrobe"
                                                                isx={true}
                                                            />
                                                        )}
                                                    {bookingDetail.stair_carry_enabled != null && (
                                                        <AdditionalServiceItem
                                                            quantity={bookingDetail.stair_carry_enabled}
                                                            iconSrc={StairCarryIcon}
                                                            description="Stair Carry"
                                                            isx={true}
                                                        />
                                                    )}
                                                    {bookingDetail.longpush_enabled != null && (
                                                        <AdditionalServiceItem
                                                            quantity={bookingDetail.longpush_enabled}
                                                            iconSrc={LongPushIcon}
                                                            description="Long Push"
                                                            isx={true}
                                                        />
                                                    )}
                                                    {bookingDetail.insurance != null &&
                                                        bookingDetail.insurance == "Yes" && (
                                                            <AdditionalServiceItem
                                                                quantity={bookingDetail.insurance}
                                                                iconSrc={InsuranceIcon}
                                                                description="Insurance"
                                                            />
                                                        )}
                                                    {bookingDetail.tail_gate != null &&
                                                        bookingDetail.tail_gate == "Yes" && (
                                                            <AdditionalServiceItem
                                                                quantity={bookingDetail.tail_gate}
                                                                iconSrc={TailGateIcon}
                                                                description="Tail Gate"
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>

                        {/* Column for Amount */}
                        {bookingDetail.total_amount != null &&
                            bookingDetail.total_amount != 0 && (
                                <div className="col-md-4 col-12 d-flex align-items-stretch">
                                    <div className="details-card pb-4">
                                        <div className="details-card-header">
                                            <h5>Amount</h5>
                                        </div>
                                        <div className="container mt-3">
                                            <div className="details-card-body">
                                                {bookingDetail.vehicle_amount != null &&
                                                    bookingDetail.vehicle_amount != 0 && (
                                                        <AmountItem
                                                            itemKey={`Lorry ${bookingDetail?.vehicle_type}`}
                                                            value={bookingDetail.vehicle_amount}
                                                        />
                                                    )}
                                                {bookingDetail.service_type == "Manpower" &&
                                                    bookingDetail.manpower_count != 0 && (
                                                        <AmountItem
                                                            itemKey={`${bookingDetail?.manpower_count} Manpower ${bookingDetail?.manpower_hour_count} Hours`}
                                                            value={bookingDetail.total_amount}
                                                        />
                                                    )}
                                                {bookingDetail.service_type == "Disposal" &&
                                                    bookingDetail.amount != 0 && (
                                                        <AmountItem
                                                            itemKey={`Lorry ${bookingDetail?.vehicle_type}`}
                                                            value={bookingDetail.amount}
                                                        />
                                                    )}
                                                {bookingDetail.manpower_amount != null &&
                                                    bookingDetail.manpower_amount != 0 && (
                                                        <AmountItem
                                                            itemKey={"Manpower"}
                                                            value={bookingDetail.manpower_amount}
                                                        />
                                                    )}
                                                {bookingDetail.box_amount != null &&
                                                    bookingDetail.box_amount != 0 && (
                                                        <AmountItem
                                                            itemKey={"Box"}
                                                            value={bookingDetail.box_amount}
                                                        />
                                                    )}
                                                {bookingDetail.shrink_wrap_amount != null &&
                                                    bookingDetail.shrink_wrap_amount != 0 && (
                                                        <AmountItem
                                                            itemKey={"Shrink Wrapping"}
                                                            value={bookingDetail.shrink_wrap_amount}
                                                        />
                                                    )}
                                                {bookingDetail.bubble_wrap_amount != null &&
                                                    bookingDetail.bubble_wrap_amount != 0 && (
                                                        <AmountItem
                                                            itemKey={"Bubble Wrapping"}
                                                            value={bookingDetail.bubble_wrap_amount}
                                                        />
                                                    )}
                                                {bookingDetail.dining_table_amount != null &&
                                                    bookingDetail.dining_table_amount != 0 && (
                                                        <AmountItem
                                                            itemKey={"Dining Table"}
                                                            value={bookingDetail.dining_table_amount}
                                                        />
                                                    )}
                                                {bookingDetail.table_amount != null &&
                                                    bookingDetail.table_amount != 0 && (
                                                        <AmountItem
                                                            itemKey={"Office Table"}
                                                            value={bookingDetail.table_amount}
                                                        />
                                                    )}
                                                {bookingDetail.bed_amount != null &&
                                                    bookingDetail.bed_amount != 0 && (
                                                        <AmountItem
                                                            itemKey={"Bed"}
                                                            value={bookingDetail.bed_amount}
                                                        />
                                                    )}
                                                {bookingDetail.wardrobe_amount != null &&
                                                    bookingDetail.wardrobe_amount != 0 && (
                                                        <AmountItem
                                                            itemKey={"Wardrobe"}
                                                            value={bookingDetail.wardrobe_amount}
                                                        />
                                                    )}
                                                {bookingDetail.stair_carry_enabled_amount != null &&
                                                    bookingDetail.stair_carry_enabled_amount != 0 && (
                                                        <AmountItem
                                                            itemKey={"Stair Carry"}
                                                            value={bookingDetail.stair_carry_enabled_amount}
                                                        />
                                                    )}
                                                {bookingDetail.longpush_enabled_amount != null &&
                                                    bookingDetail.longpush_enabled_amount != 0 && (
                                                        <AmountItem
                                                            itemKey={"Long Push"}
                                                            value={bookingDetail.longpush_enabled_amount}
                                                        />
                                                    )}
                                                {bookingDetail.discount != null &&
                                                    bookingDetail.discount != 0 && (
                                                        <AmountItem
                                                            itemKey={"Discount"}
                                                            value={bookingDetail.discount}
                                                        />
                                                    )}
                                                {bookingDetail.sst_amount != null &&
                                                    bookingDetail.sst_amount != 0 && (
                                                        <AmountItem
                                                            itemKey={"SST Charges(6%)"}
                                                            value={bookingDetail.sst_amount}
                                                        />
                                                    )}
                                                <hr></hr>
                                                {bookingDetail.total_amount != null &&
                                                    bookingDetail.total_amount != 0 && (
                                                        <div className="row">
                                                            <div className="col-6  total-price">Total</div>
                                                            <div className="col-6  text-end total-price">
                                                                {bookingDetail.total_amount}MYR
                                                            </div>
                                                        </div>
                                                    )}
                                                {bookingDetail.grand_total != null &&
                                                    bookingDetail.grand_total != 0 && (
                                                        <div className="row">
                                                            <div className="col-6  total-price">Grand Total</div>
                                                            <div className="col-6  text-end total-price">
                                                                {bookingDetail.grand_total}MYR
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default MybookingDeatils