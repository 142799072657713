import Abouimg4 from '../../img/The Movers Online_the lorry.webp';

const PindhaRumha = () => {
    return (
        <>
            <section className="banner-section position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="local-heading">Pindah Rumah – Lori Sewa Berpatutan</h1>
                            <p className='mb-1'>by The Movers Online | Sep 21, 2020</p>
                        </div>
                    </div>
                    <div className="row mb-0">
                        <div className="col-md-4 col-12">
                            <img src={Abouimg4} className='w-100' alt="The Movers Online" />
                        </div>
                        <div className="col-md-8 col-12">
                            <h5>Minggu sebelum berpindah</h5>
                            <p className='mb-1'>• Sahkan dengan pemaju, pihak pengurusan (Management) (kalau kondo atau apartment) dan pihak yang berkenaan tarikh anda akan berpindah . (Moving OUT/IN Form)</p>
                            <p className='mb-1'>• Pastikan tuan rumah / Owner / Agent yang anda diami sekarang telah menerima notis berpindah dari anda (jika anda menyewa) dan notis adalah sebulan dari tarikh keluar rumah.</p>
                            <p className='mb-1'>• Mula kumpulkan kotak atau bekas yang sesuai yang anda perlukan untuk ‘packing’ barang-barang anda. Anda seharusnya sudah boleh membuat keputusan sama ada untuk membawa kesemuanya, buang sebahagian, jual sebahagian, tinggalkan sebahagian atau berikan/sumbangkan kepada orang lain barangbarang yang berkenaan.</p>
                            <p className='mb-1'>• Mula senaraikan semua pihak yang harus anda hubungi untuk update alamat anda (bank, syarikat utiliti, astro, kawan2, syarikat insurans, klinik/hospital/doktor, sekolah, langganan majalah atau akhbar dan lain2 yang berkenaan.</p>
                            <p className='mb-1'>• Rancang layout untuk rumah baru anda supaya barang-barang baru boleh diletakkan dengan teratur dan tidak berterabur semasa pindah nanti.</p>
                            <p className='mb-1'>• Periksa sekolah/tadika untuk anak anda (ini sebaiknya dilakukan lebih awal lagi – mungkin 2-3 bulan sebelum pindah).</p>
                            <p className='mb-1'>• Pusing kawasan baru anda – tengok di mana lokasi bank, restoran, klinik, masjid/surau, balai polis dan lain-lain yang mungkin anda perlukan.</p>

                        </div>
                        <h5>2 minggu sebelum berpindah</h5>
                        <p className='mb-1'>• Mula kemaskan barang-barang anda ke dalam bekas atau kotak yang telah anda sediakan. Pastikan kotak-kotak dilabelkan supaya tidak lupa di mana anda meletakkan barang tersebut terutamanya barang-barang kecil dan dokumen penting.</p>
                        <p className='mb-1'>• Tulis surat atau email kepada senarai yang telah anda kumpulkan sebelum ini untuk penukaran alamat. Sesetengah bank seperti Maybank memerlukan anda pergi sendiri ke cawangan mereka untuk penukaran alamat. Majoriti yang lain sekadar cukup melalui emai dan/atau melalui telefon ke ‘Khidmat Pelanggan’ mereka.</p>
                        <p className='mb-1'>• Mula asingkan atau buang barang-barang yang tidak diperlukan seperti majalah lama atau barang rosak. Kadangkala anda perlu membuat keputusan dengan lebih ‘rasional’ dan tegas supaya anda tidak membawa ‘sampah’ ke rumah baru anda. Ini biasa terjadi akibat sifat ‘sayang’ pada barang dan terlalu memikirkan kos yang pernah anda keluarkan sedangkan jika anda terus menyimpannya, ia akan hanya mengambil ruang rumah baru anda dan menjadi sampah yang anda sendiri tidak menyentuhnya.</p>
                        <p className='mb-1'>• Mula senaraikan semua pihak yang harus anda hubungi untuk update alamat anda (bank, syarikat utiliti, astro, kawan2, syarikat insurans, klinik/hospital/doktor, sekolah, langganan majalah atau akhbar dan lain2 yang berkenaan.</p>
                        <p className='mb-1'>• Pastikan semua utiliti (api, air, gas, dsb) telah disambungkan ke rumah anda.</p>
                        <p className='mb-1'>• Semak diari anda. Pastikan anda tiada program atau appointment lain pada tarikh anda berpindah.</p>

                        <h5>1 minggu sebelum berpindah</h5>
                        <p className='mb-1'>• Semak beberapa perkhidmatan pindah rumah atau lori sewa. Anggaran kos biasa lori sewa 5 tan berserta orang untuk mengangkat barang adalah lebih kurang RM500 berserta 3 pekerja untuk jarak sederhana (lebih kurang 5km). (Harga The Movers Online) Sila confirmkan awal tarikh dan masa anda mahu berpindah setelah anda menjumpai yang sesuai.</p>
                        <p className='mb-1'>• Sekiranya anda membeli perabot baru, sila uruskan dan maklumkan untuk penghantaran sebaiknya selepas anda berpindah (mungkin ada yang lebih suka memasukkan barang baru sebelum berpindah).</p>
                        <p className='mb-1'>• Basuh rumah baru anda sebelum berpindah (ni semua mesti buat kan?…) Mungkin ada yang lebih suka basuh sehari sebelum pindah.. terpulang!</p>
                        <p className='mb-1'>• Settle semua bil-bil anda.</p>
                        <h5>1 Hari sebelum Berpindah</h5>
                        <p className='mb-1'>• 1 hari sebelum berpindah, hubungi semula perkhidmatan pindah rumah/lori sewa untuk pengesahan.</p>
                        <p className='mb-1'>• Hubungi pihak pengurusan rumah baru anda serta sekuriti supaya tiada masalah masa berpindah. Sesetengah tempah hanya membenarkan pindah masuk rumah pada hari dan waktu tertentu sahaja. (The Movers beroperasi 7 Hari Seminggu)</p>
                        <p className='mb-1'>• Sila tanda dan asingkan barangan yang akan anda sendiri membawanya dan yang akan dipindahkan menggunakan lori. Sila pastikan dokumen penting dan barangan berharga anda diasingkan dan dibawa sendiri.</p>
                        <p className='mb-1'>• Kumpulkan barangan yang mungkin anda perlukan sebaik sahaja memasuki rumah baru anda di dalam satu kotak. Barangan ini seperti pencuci pembersih, pinggan mangkuk pakai buang atau plastik, beg sampah, tali, dsbnya.</p>
                        <p className='mb-1'>• Pastikan peti sejuk anda dibersihkan! Ramai yang asyik kemas diluar tetapi terlupa dalam fridge mereka..</p>
                        <h5>Hari Berpindah</h5>
                        <p className='mb-1'>• Hubungi sekali lagi perkhidmatan pindah rumah/lori sewa pada pagi hari anda berpindah. Terdapat kebarangkalian mereka terlupa atau lewat terutamanya jika anda gunakan servis lori sewa biasa dan bukan syarikat yang ‘specialise’ dalam pindah rumah. Dengan The Movers Anda tidak akan mempunyai masalah ini sebab , Ada Customer service akan sentiasa follow up dengan you .</p>
                        <p className='mb-1'>• Breakfast awal – terutamanya jika anda pindah waktu pagi. Jika tidak anda mungkin kelaparan sebab proses ni ambil masa yang agak panjang (bergantung kepada barang dan jarak rumah baru anda).</p>
                        <p className='mb-1'>• Ambil final ‘meter reading’ untuk elektrik dan air anda. Ambil gambar pun boleh sebagai bukti terutamanya jika anda menyewa rumah sekarang.</p>
                        <p className='mb-1'>• Pastikan lori tersebut tahu lokasi rumah baru anda atau anda boleh ‘lead’ mereka ke sana.</p>
                        <p className='mb-1'>• Ambil perhatian terhadap proses ‘loading’ dan ‘unloading’ barang kerana terdapat kemungkinan barang anda akan rosak kerana dipindahkan dengan kasar apabila tiada pemerhatian anda..</p>
                        <p className='mb-1'>• Setelah anda pastikan semua barang telah dipindahkan dengan selamat, bayar perkhidmatan lori sewa tersebut dan mungkin sedikit tips tambahan jika anda berpuashati .</p>

                    </div>
                </div>
            </section>
        </>
    );
}

export default PindhaRumha;
