import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BOXLOGO from "../img/box.png";
import Budged from "../img/budged.svg";
import Disposal from "../img/disposal.svg";
import Manpower5 from "../img/extraman.png";
import Headerlogo from "../img/headerlogonew.png";
import Slider4 from "../img/lori_sewa.jpg";
import MAPLOGO from "../img/map.png";
import Packer1 from "../img/packer-1.png";
import Packer2 from "../img/packer-2.png";
import Packer3 from "../img/packer-3.png";
import Packer4 from "../img/packer-4.png";
import Packer5 from "../img/packer-5.png";
import Slider3 from "../img/pindah_office.jpg";
import Slider2 from "../img/pindah_rumah.jpg";
import Premium from "../img/premium.svg";
import Slider1 from "../img/sewa_lori.webp";
import STARLOGO from "../img/star.png";
import Ser1 from "../img/work-1.png";
import Ser2 from "../img/work-2.png";
import Ser3 from "../img/work-3.png";
import Ser4 from "../img/work-4.png";
import { BookingSection } from "./Booking/bookingSection";
import QuestionsLists from "./questionsLists";

const Index = () => {
  const [filterReviewLists, setFilterReviewLists] = useState([]);
  const [reviewLists, setReviewLists] = useState([]);
  const getGoogleReviewAPI = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/get_google_reviews`)
      .then((response) => setFilterReviewLists(response?.data?.result?.reviews))
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    //getGoogleReviewAPI();
  }, []);
  useEffect(() => {
    if (filterReviewLists.length > 0)
      setReviewLists(_.filter(filterReviewLists, (data) => data.rating >= 4));
  }, [filterReviewLists]);
  return (
    <>
      <section className="banner-section position-relative">
        <div className="container">
          <div className="row">
            <div className="col-md-7 order-2 order-lg-1">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="best-movers">
                    Malaysia’s Largest Packers and Movers
                  </h1>
                  <h6 className="banner-sub1">
                    Service that fits your budget with quality assurance!
                  </h6>
                </div>
              </div>
              <div
                id="carouselExampleControls"
                className="carousel slide slider-top-style"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src={Slider1}
                      className="w-100 slider-name"
                      alt="..."
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={Slider2}
                      className="w-100 slider-name"
                      alt="..."
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={Slider3}
                      className="w-100 slider-name"
                      alt="..."
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={Slider4}
                      className="w-100 slider-name"
                      alt="..."
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
              <div className="row mt-4">
                <div className="col-md-4 col-12 d-flex align-items-stretch only-mobile-stylebottom">
                  <div className="pro-box">
                    <div className="d-flex">
                      <div className="me-2">
                        <img src={MAPLOGO} className="productimg" alt='Map Logo'/>
                      </div>
                      <div className="">
                        <p className="heading">Regular Service Update</p>
                        <p className="banner-sub">Real time update</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-12 d-flex align-items-stretch only-mobile-stylebottom">
                  <div className="pro-box">
                    <div className="d-flex">
                      <div className="me-2">
                        <img src={STARLOGO} className="productimg" alt='Timely pickup & delivery'/>
                      </div>
                      <div className="">
                        <p className="heading">4.8 / 5 Rating</p>
                        <p className="banner-sub">Timely pickup & delivery</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-12 d-flex align-items-stretch only-mobile-stylebottom">
                  <div className="pro-box">
                    <div className="d-flex">
                      <div className="me-2">
                        <img src={BOXLOGO} className="productimg" alt='Reliable packaging'/>
                      </div>
                      <div className="">
                        <p className="heading">4.9 / 5 Rating</p>
                        <p className="banner-sub">Reliable packaging</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="services-section-inner">
                <h4 className="service-text">Services We Offer</h4>
                <div className="row">
                  <div className="col-md-3 col-6 text-center">
                    <div className="pro-text">
                      <Link to={"/budget"} className="box-list-style">
                        <div className="">
                          <img src={Budged} alt='Budget Moving'/>
                        </div>
                        <div className="">
                          <h6 className="pro-text-inner">Budget Moving</h6>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3 col-6 text-center">
                    <div className="pro-text">
                      <Link to={"/premimum"} className="box-list-style">
                        <div className="">
                          <img src={Premium} alt='Premium Moving'/>
                        </div>
                        <div className="">
                          <h6 className="pro-text-inner">Premium Moving</h6>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3 col-6 text-center">
                    <div className="pro-text">
                      <Link to={"/disposal"} className="box-list-style">
                        <div className="">
                          <img src={Disposal} alt='Disposal Service'/>
                        </div>
                        <div className="">
                          <h6 className="pro-text-inner">Disposal Service</h6>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3 col-6 text-center">
                    <div className="pro-text">
                      <Link to={"/manpower"} className="box-list-style">
                        <div className="">
                          <img src={Manpower5} alt='Manpower Service'/>
                        </div>
                        <div className="">
                          <h6 className="pro-text-inner">Manpower Service</h6>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="services-section-inner">
                <h4 className="service-text">How it Works?</h4>
                <div className="row">
                  <div className="col-md-2 col-2 text-center">
                    <img src={Ser1} className="serviceimg1" alt='Share your Shifting Requirement'/>
                  </div>
                  <div className="col-md-8 col-8 text-center">
                    <h3 className="works-heading">
                      Share your Shifting Requirement
                    </h3>
                    <p className="works-sub-heading">
                      Help us by providing when and where do you want to move
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-2 text-center">
                    <img src={Ser2} className="serviceimg1" alt='Receive Free Instant Quote'/>
                  </div>
                  <div className="col-md-8 col-8 text-center">
                    <h3 className="works-heading">
                      Receive Free Instant Quote
                    </h3>
                    <p className="works-sub-heading">
                      Get guaranteed lowest priced quote for your shifting
                      instantly
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-2 text-center">
                    <img src={Ser3} className="serviceimg" alt='Assign Quality Service Expert'/>
                  </div>
                  <div className="col-md-8 col-8 text-center">
                    <h3 className="works-heading">
                      Assign Quality Service Expert
                    </h3>
                    <p className="works-sub-heading">
                      To ensure safe relocation, a quality service expert will
                      be allotted for your movement
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-2 text-center">
                    <img src={Ser4} className="serviceimg" alt='Leave the Heavy Lifting to Us'/>
                  </div>
                  <div className="col-md-8 col-8 text-center">
                    <h3 className="works-heading">
                      Leave the Heavy Lifting to Us
                    </h3>
                    <p className="works-sub-heading">
                      Enjoy hassle-free, on-time movement of your household
                      goods
                    </p>
                  </div>
                </div>
              </div>
              <div className="services-section-inner">
                <h4 className="service-text">Why The Mover's Online?</h4>
                <div className="row">
                  <div className="col-md-2 col-2 text-center">
                    <img src={Packer1} className="serviceimg1" alt='Lowest Price Guarantee'/>
                  </div>
                  <div className="col-md-8 col-8 text-center">
                    <h3 className="works-heading">Lowest Price Guarantee</h3>
                    <p className="works-sub-heading">
                      Moving at a price you can afford - we’ll match any
                      competitor’s quote
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-2 text-center">
                    <img src={Packer2} className="serviceimg1" alt='Best Quality Service'/>
                  </div>
                  <div className="col-md-8 col-8 text-center">
                    <h3 className="works-heading">Best Quality Service</h3>
                    <p className="works-sub-heading">
                      Safe and Reliable Packaging and Moving Services
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-2 text-center">
                    <img src={Packer3} className="serviceimg" alt='Reschedule your shifting anytime'/>
                  </div>
                  <div className="col-md-8 col-8 text-center">
                    <h3 className="works-heading">
                      Reschedule your shifting anytime
                    </h3>
                    <p className="works-sub-heading">
                      Change your shifting date as per your convenience.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-2 text-center">
                    <img src={Packer4} className="serviceimg" alt='Support Assistances'/>
                  </div>
                  <div className="col-md-8 col-8 text-center">
                    <h3 className="works-heading">Support Assistances</h3>
                    <p className="works-sub-heading">
                      Dedicated support assistance for quick query resolution
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-2 text-center">
                    <img src={Packer5} className="serviceimg" alt='Professional Labour'/>
                  </div>
                  <div className="col-md-8 col-8 text-center">
                    <h3 className="works-heading">Professional Labour</h3>
                    <p className="works-sub-heading">
                      Expertly packing and moving your belongings
                    </p>
                  </div>
                </div>
              </div>
              <div className="services-section-inner">
                <h4 className="service-text">
                  Service  Comparison
                </h4>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="text-left table-width-style text-start">
                          Services
                        </th>
                        <th className="text-center">
                          {" "}
                          <p className="xstyle">X </p> Movers{" "}
                        </th>
                        <th className="text-center">
                          <img src={Headerlogo} className="header-style-size" alt='Header Logo'/>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="">Vehicle Assurance</td>
                        <td className="text-center">
                          <i
                            className="fa fa-check text-primary-color"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center">
                          <i
                            className="fa fa-check text-primary-color"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Verified Professional Driver
                        </td>
                        <td className="text-center">
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </td>
                        <td className="text-center">
                          <i
                            className="fa fa-check text-primary-color"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">Regular Update</td>
                        <td className="text-center">
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </td>
                        <td className="text-center">
                          <i
                            className="fa fa-check text-primary-color"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Packaging & Unpacking Of household goods
                        </td>
                        <td className="text-center">
                          <i
                            className="fa fa-check text-primary-color"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center">
                          <i
                            className="fa fa-check text-primary-color"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Dismantling & Re-Assemble Of Cot
                        </td>
                        <td className="text-center">
                          <i
                            className="fa fa-check text-primary-color"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center">
                          <i
                            className="fa fa-check text-primary-color"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">Labour</td>
                        <td className="text-center">
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </td>
                        <td className="text-center">
                          <i
                            className="fa fa-check text-primary-color"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Bubble/ Foam Wrapping Of
                        </td>
                        <td className="text-center">
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </td>
                        <td className="text-center">
                          <i
                            className="fa fa-check text-primary-color"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          On Demand Warehouse Storage
                        </td>
                        <td className="text-center">
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </td>
                        <td className="text-center">
                          <i
                            className="fa fa-check text-primary-color"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {reviewLists && reviewLists.length > 0 && (
                <>
                  <div className="services-section-inner">
                    <h4 className="service-text">Our Happy Customers</h4>
                    <div
                      id="carouselExampleSlidesOnly"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-indicators">
                        {reviewLists.map((reviewList, index) => (
                          <button
                            type="button"
                            data-bs-target="#carouselExampleSlidesOnly"
                            data-bs-slide-to={index}
                            className={`${(index === 0) ? "active" : ""}`}
                            aria-label={`Slide ${index + 1}`}
                          ></button>
                        ))}
                      </div>
                      <div className="carousel-inner">
                        {reviewLists.map((reviewList, index) => (
                          <div
                            className={`carousel-item ${index === 0 ? "active" : ""
                              }`}
                          >
                            <div className="text-center">
                              <img
                                src={reviewList?.profile_photo_url}
                                className="testimonails-box"
                                alt="..."
                              />
                            </div>
                            <div className="text-center">
                              <h5 className="card-title mt-2 mb-0 text-center">
                                {reviewList?.author_name}
                              </h5>
                              <p className="mb-1">
                                {[...Array(reviewList["rating"])].map(
                                  (x, index) => (
                                    <i
                                      className="fa fa-star me-1"
                                      style={{ color: "#d39f09" }}
                                      aria-hidden="true"
                                      key={index}
                                    ></i>
                                  )
                                )}
                              </p>
                              <p>{reviewList?.text}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <QuestionsLists />
            </div>
            <div className="col-md-5 col-12 order-1 order-lg-2">
              <BookingSection />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;