import Abouimg1 from '../../img/The Movers Online_lorry for rent.webp';

const TipsPending = () => {
    return (
        <>
            <section className="banner-section position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="local-heading">7 tips penting yang perlu diamalkan sebelum pindah rumah</h1>
                            <p className='mb-1'>by The Movers Online | Sep 21, 2020</p>
                        </div>
                    </div>
                    <div className="row mb-0">
                        <div className="col-md-4 col-12">
                            <img src={Abouimg1} className='w-100' alt="The Movers Online" />
                        </div>
                        <div className="col-md-8 col-12">
                            <h5>Barang asas yang perlu ada :</h5>
                            <p className='mb-1'>• Cari kotak bersaiz besar dan sederhana. (The Movers online ada menyediakan Kotak Double Wall yang Sesuai untuk proses Pemindahan Rumah).</p>
                            <p className='mb-1'>• Plastik pembungkus (untuk mengelakkan sebarang calar berlaku pada perabot semasa proses angkat masuk dalam lori nanti).</p>
                            <p className='mb-4'>• Masking tape, pen marker dan gunting (untuk labelkan barang yang ada dalam kotak).</p>

                            <h5>Jom pindah rumah!</h5>
                            <p className='mb-1'>• Tentulah survey lokasi rumah yang bakal dipindah masuk.</p>
                            <p className='mb-1'>• Berhubung dengan pihak pengurusan kawasan perumahan
                                berkaitan prosedur pindah masuk. (Mengikut kawasan rumah
                                yang korang nak pindah juga ya).</p>
                            <p className='mb-1'>• Survey harga lori yang bersesuaian untuk mengangkut semua
                                barangan kita nanti. (Kena survey tiga hingga empat tempat)
                                pastikan survey review kat google , Facebook , dah dapat
                                gambaran macam mana Servis Nanti . J</p>
                            <p className='mb-4'>•   Apa lagi, jom pindah rumah!</p>


                        </div>


                    </div>
                </div>
            </section>
        </>
    );
}

export default TipsPending;
