import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <>
      <section className="common-top-style mybooking-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
                <h3 className="about-heading mb-3 text-center">404</h3>
                <h3 className="about-heading mb-2 text-center">Not found</h3>
                <p className="common-para-style mb-1 text-center">The resource requested could not be found on this server!</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PageNotFound;
