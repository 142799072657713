import React from "react";
import Header from "../Header/header";
import Headerlogo from '../../src/img/registerlogo.webp';
import { Link } from 'react-router-dom';
import Footer from "../Footer/footer";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { useSelector } from 'react-redux';

export const IndexLayout = (props) => {
  const { isLoading } = useSelector(state => state.loader)
  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Header />
      {props.children}
      <Footer />
    </>
  )
}

export const LoginLayout = (props) => {
  const { isLoading } = useSelector(state => state.loader)
  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Header />
      <section className="common-top-style">
        <div className="overall-box-register">
          <div className="register-left-side">
            <Link to="/"> <img src={Headerlogo} className='register-logo-size' alt='Header Logo'/></Link>
            <h3>Signing to Your Account</h3>
            <p>Enter your email or phone to login</p>
          </div>
          <div className="register-right-side">
            {props.children}
          </div>
        </div>
      </section>
    </>
  )
}

export const LoggedLayout = (props) => {
  const { isLoading } = useSelector(state => state.loader)
  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Header />
      {props.children}
      <Footer />
    </>
  )
}